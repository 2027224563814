<template>
  <div
    v-if="store"
    id="cartPage"
    class="border-2 z-3 bg-bg_color rounded-t-xl border-e_store-green/80 my-5"
  >
    <div class="bg-bg_color rounded-t-xl brightness-95 relative">
      <EStoreLink
        to="/"
        :aria-label="t('aria_page_label', { page: store.name })"
      >
        <div class="flex border-b-2 border-text-200 items-center p-3">
          <NuxtImg
            provider="cacheIpx"
            preset="modified"
            loading="lazy"
            :src="store.icon"
            :alt="store.icon"
            width="86"
            height="86"
            class="object-cover object-center w-[86px] h-[86px] rounded-full mx-2"
          />

          <div class="mx-1">
            <div
              class="sm:text-lg md:text-xl font-semibold text-e_store-light truncate"
            >
              {{ store.name }}
            </div>
            <div
              class="md:flex md:flex-wrap text-text_color md:font-medium text-sm md:text-base"
            >
              <div>
                {{ t('number_of_materials') }}:
                {{ cart.items.length }}
              </div>
              <div class="w-3"></div>
              <div v-if="totalPrice">{{ t('total') }}: {{ totalPrice }}</div>
            </div>
          </div>
        </div>
      </EStoreLink>
      <CartTable :store="store" :table="table" />
    </div>
    <div class="bg-bg_alt_color">
      <div class="p-2 font-semi pt-3">
        <v-expansion-panels v-model="expansionPanelValue">
          <v-expansion-panel>
            <v-expansion-panel-title>
              {{ t('orders_details') }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="bg-bg_alt_color p-2">
                <div class="flex flex-wrap-reverse">
                  <div
                    class="order-2 w-full md:w-7/12 md:order-1 md:rtl:pr-2 md:ltr:pl-2"
                  >
                    <CartItemOffer
                      v-for="(itemsOffer, key) in itemsWithOffers"
                      :key="`cart-item-offer-${key}`"
                      :offer-items="itemsOffer"
                      :offer-number="key"
                    />

                    <div v-if="itemsWithOutOffers.length">
                      <CartNormalItem
                        v-for="item in itemsWithOutOffers"
                        :key="`cart-item-${item.material.id}`"
                        :item="item"
                        :offers="offers"
                        @open-dialog="emit('open-dialog', $event)"
                      />
                    </div>
                  </div>

                  <div class="order-1 w-full md:w-5/12 md:order-2">
                    <CartCostCart
                      class="mb-2 mt-1"
                      :items-with-out-offers="itemsWithOutOffers"
                      :items-with-offers="itemsWithOffers"
                      :table="table"
                      @select-shipping-way="selectedShippingWay = $event"
                      @open-dialog="emit('open-dialog', $event)"
                      @set-total-price="totalPrice = $event"
                    />
                  </div>
                </div>
                <div
                  v-if="store.cart_hint"
                  class="font-medium text-e_store-light mt-4 flex items-start"
                >
                  <v-icon
                    icon="mdi mdi-information-variant-circle-outline"
                    :size="20"
                    class="mx-1 flex-shrink-0"
                  />
                  <div class="break-all">{{ store.cart_hint }}</div>
                </div>
                <div class="p-1 sm:w-max">
                  <CartErrors />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GroupOffer, ItemStoreCart } from '~~/composables/useMenuModel'

const props = defineProps<{
  storeWithItems: ItemStoreCart
  offers: GroupOffer[]
}>()

const { store } = useDomainState()
const selectedShippingWay = ref<ShippingWay | null>(null)
const expansionPanelValue = ref<number | undefined>(0)
const table = computed(() => {
  return cart.getTableByID(store.value?.id)
})
const totalPrice = ref<string | undefined>(undefined)
const emit = defineEmits(['open-dialog'])

const cart = useCartStore()
const { t } = useI18n()

const itemsWithOutOffers = computed(() => {
  return props.storeWithItems?.items?.withOutOffers || []
})

const itemsWithOffers = computed(() => {
  return props.storeWithItems?.items?.withOffers || []
})
</script>

<style>
.v-expansion-panel-text__wrapper {
  padding: 4px 4px 4px !important;
  flex: 1 1 auto;
  max-width: 100%;
}
</style>
